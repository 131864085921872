
@import './reset.css';
@import './variables.css';
@import './fonts/fonts.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}


.element-animation  {
    opacity: 0;
  transform: translateY(30%);
}
.element-animation.element-show {
  opacity: 1;
  transition: all 1s;
  transform: translateY(0%); 
}

