@font-face {
    font-family: "Montserrat"; 
    src: url("./Montserrat-Regular.ttf"); 
    src:  url("./Montserrat-Regular.woff") format("woff"),
    url("./Montserrat-Regular.woff2") format("woff2"),
    url("./Montserrat-Regular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 400; 
    }

    @font-face {
        font-family: "Montserrat"; 
        src: url("./Montserrat-Medium.ttf"); 
        src:  url("./Montserrat-Medium.woff") format("woff"),
        url("./Montserrat-Medium.woff2") format("woff2"),
        url("./Montserrat-Medium.ttf") format("truetype"); 
        font-style: normal; 
        font-weight: 500; 
        }

        @font-face {
            font-family: "Montserrat"; 
            src: url("./Montserrat-SemiBold.ttf"); 
            src:  url("./Montserrat-SemiBold.woff") format("woff"),
            url("./Montserrat-SemiBold.woff2") format("woff2"),
            url("./Montserrat-SemiBold.ttf") format("truetype"); 
            font-style: normal; 
            font-weight: 600; 
            }