:root {
    --dark-brown: #30191a;
    --common-brown: #3c272c;
    --gray-brown: #705a5a;
    --light-brown: #a17470;
    --light-beige: #ece2de;
    --common-gray: #dbd0cc;
    --common-white: #fbfcfe;
    --max-container-width: 1590px;
    --tablet-breakpoint: 1250px;
}

